// i18next-extract-mark-ns-start products-valdyn-valvetrain

import { VALDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductValdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							VALDYN delivers a kinematic and dynamic solution to
							effectively generate and analyse valvetrain design
							including geometry, camshaft profile and valve-spring
							design.
						</p>
						<p>
							VALDYN Kinematics caters to the design and kinematic
							analysis of valvetrain systems. Using a building-block
							approach, VALDYN Kinematics provides users with a
							library of select standard and unconventional
							valve trains. All valvetrain types can be analysed
							including linearly translating and swinging followers
							with or without a push rod and rocker system. VALDYN
							Kinematics can be used to assess an existing cam design
							or to generate a cam profile using the Realis Simulation
							formerly Ricardo Software ‘Multipol’ method or a general
							spline method. A number of methods of spring pack design
							are available to allow combined optimisation with
							camshaft profile design. VALDYN Kinematics produces
							comprehensive outputs to enable spring/cam design to be
							assessed, including cam contact Hertzian stress, oil
							film thickness, cam wear, spring cover, spring stress
							and spring natural frequency.
						</p>
						<p>
							Data can easily be transferred to VALDYN Dynamics for
							assessment of the design in the context of the timing
							drive and to assess dynamic aspects such as spring
							surge, valve seating velocity and valve bounce.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>Building-block approach to model construction</li>
							<li>Caters for novel systems</li>
							<li>
								Tribological analysis from kinematic and dynamic
								standpoints
							</li>
							<li>Detailed spring model including coil clash</li>
							<li>
								Dynamic analysis in the context of the complete
								engine
							</li>
							<li>Advanced spline methods for cam design</li>
							<li>Arbitrary shaped swinging cam follower</li>
							<li>Spring pack natural frequency</li>
							<li>
								Elliptical and ovate spring modelling with accurate
								stress and fatigue outputs
							</li>
						</ul>
					</Trans>
				</div>

				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{VALDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductValdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-valdyn-valvetrain", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/valdyn-hero.jpg" }) {
		...imageBreaker
	}
}
`;

